import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const userContext = createContext();

function ApiFetchContext({ children }) {
  const [userData, setUserData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const userUrl = "https://apidata.kitezone.in/api/users";
  const businessUrl = "https://apidata.kitezone.in/api/businesses";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get(userUrl);
        console.log("Fetched Data:", userResponse.data); // Log API response
        setUserData(userResponse.data); // Ensure this is an array
        localStorage.setItem("userContext", JSON.stringify(userResponse.data)); // Save to localStorage
        const businessResponse = await axios.get(businessUrl);
        console.log("Fetched Business Data:", businessResponse.data);
        setBusinessData(businessResponse.data);
        localStorage.setItem("businessContext", JSON.stringify(businessResponse.data));
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <userContext.Provider value={{userData, businessData}}>
      {children}
    </userContext.Provider>
  );
}

export default ApiFetchContext;


