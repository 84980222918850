import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
// import '../styles/UserDetails.css';

const UserDetailsPage = () => {
  const { userId } = useParams();  // Get userId from the URL params
  const [user, setUser] = useState(null);
  const [business, setBusiness] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        // Fetch user details based on the userId
        const userResponse = await axios.get(`https://apidata.kitezone.in/api/users/${userId}`);
        setUser(userResponse.data); // Save user data

        // Fetch business details for the given userId
        const businessResponse = await axios.get(`https://apidata.kitezone.in/api/business/${userId}`);
        setBusiness(businessResponse.data); // Save business data

      } catch (error) {
        setError("Unable to fetch user or business details.");
        console.error("Error fetching user or business details:", error);
      }
    };

    fetchUserDetails(); // Trigger API call on page load
  }, [userId]);

  if (error) return <div>{error}</div>;
  if (!user || !business) return <div>Loading...</div>;

  return (
    <div className="user-details-container">
      <h2>User Details</h2>
      
      {/* Display user details */}
      <div className="user-details">
        <img
          src={user.image || 'https://kitezone.in/images/users/user_pic_1725948652.png'}
          alt={user.name}
          className="user-image"
        />
        <div className="user-info">
          <p><strong>Name:</strong> {user.name}</p>
          <p><strong>Email:</strong> {user.email}</p>
          <p><strong>Phone:</strong> {user.phone || 'Not Provided'}</p>
          <p><strong>District:</strong> {user.district || 'Not Provided'}</p>
        </div>
      </div>

      {/* Display business details */}
      <h3>Business Details</h3>
      <div className="business-details">
        <p><strong>Business Name:</strong> {business.name || 'No Business Name'}</p>
        <p><strong>Description:</strong> {business.description || 'No description available'}</p>
        <p><strong>Location:</strong> {business.location || 'Not provided'}</p>
        <p><strong>Products:</strong> {business.products ? business.products.join(', ') : 'No products listed'}</p>
      </div>
    </div>
  );
};

export default UserDetailsPage;


