import React, { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import '../styles/Home.css';
import NavBar from './NavBar';
import { useNavigate } from 'react-router-dom';
import { userContext } from './ApiFetchContext';

const Home = () => {
  const [randomUsers, setRandomUsers] = useState([]);
  const {businessData} = useContext(userContext)

  useEffect(() => {
    const usersData = JSON.parse(localStorage.getItem("userContext"));
     
    if (usersData && usersData.length > 0) {
      const shuffled = usersData.sort(() => 0.5 - Math.random());
      setRandomUsers(shuffled.slice(0, 5)); // Display 5 random users
    }
    
  }, []);

  const navigate = useNavigate();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1200,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <NavBar />
      <div className="head">
        <div className="section-one">
          <h1 style={{ color: "white", fontFamily: "'Times New Roman', Times, serif" }}>BAP Volume 4</h1>
        </div>
        <div style={{ marginTop: "10px", fontStyle: "oblique", fontSize: "20px", marginLeft: "10px" }}>
          Members
        </div>
        <div className='section-two'>
        <div className="carousel-container">
          {randomUsers && randomUsers.length > 0 ? (
            <Slider {...settings}>
              {randomUsers.map((user) => (
                <div
                  key={user.id}
                  className="carousel-card"
                  onClick={() => navigate(`/user/${user.id}`)}
                >
                  <div className="card-content">
                    <img
                      src={user.image || 'https://kitezone.in/images/users/user_pic_1725948652.png'}
                      alt={user.name || 'No Name'}
                      className="card-img"
                    />
                    <div className="card-info">
                      <h3>{user.name || 'Name Not Available'}</h3>
                      <p>{user.email || 'E-mail Not Available'}</p>
                      <p>{user.district || 'Location Not Available'}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <p>Loading data...</p>
          )}
        </div>
        <div className="view">
          <button
            onClick={() => {
              navigate('/more-members');
            }}
          >
            View More
          </button>
        </div>
        </div>
       
        <div className='section-three'>
        <div style={{ marginTop: "10px", fontStyle: "oblique", fontSize: "20px", marginLeft: "10px" }}>
          Business
        </div>
        <div className="carousel-container">
          {businessData && businessData.length > 0 ?  (
            <Slider {...settings}>
              {businessData.map((b) => (
                <div
                  key={b.id}
                  className="carousel-card"
                  onClick={() => navigate(`/businesses/${b.id}`)}
                >
                  <div className="card-content-b">
                    
                    <div className="card-info">
                      <h3>{b.name || 'Name Not Available'}</h3>
                      <p>{b.email || 'E-mail Not Available'}</p>
                      <p>{b.category || 'Location Not Available'}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <p>Loading data...</p>
          )}
        </div>
        <div className="view">
          <button
            onClick={() => {
              navigate('/more-business');
            }}
          >
            View More
          </button>
        </div>
        </div>
      </div>
    </>
  );
};

export default Home;
