import React, { useState, useEffect } from "react";
import "../styles/ProfilePage.css"; 
import NavBar from "./NavBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const [business, setBusiness] = useState(null);
  const navigate = useNavigate();

  // Fetch user data from localStorage
  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    console.log("Fetched authUser from localStorage:", authUser); // Debugging log
    
    if (authUser && authUser.user) {
      setUser(authUser.user);
    } else {
      console.error("No user data found in localStorage or data is improperly structured.");
    }
  }, []);

  // Fetch business data from API
  useEffect(() => {
    const fetchBusinessData = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("No authentication token found.");
        return;
      }

      try {
        const response = await axios.get("https://apitest.kitezone.in/api/businesses", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setBusiness(response.data);
        console.log("Fetched business data:", response.data); // Debugging log
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    };

    fetchBusinessData();
  }, []);

  const handleEditClick = () => {
    navigate("/edit-profile"); // Redirect to the edit profile page
  };

  return (
    <>
      <NavBar />
      <div style={{ backgroundColor: "#3498db", height: "60px" }}></div>
      <div className="profile-container">
        {user ? (
          <div className="profile-card">
            {/* Profile Picture */}
            <div className="profile-picture">
              <img
                src={user.image || "https://kitezone.in/images/users/user_pic_1725948652.png"}
                alt="Profile"
              />
            </div>

            {/* Name and Title */}
            <h2 className="profile-name">{user.name}</h2>

            {/* Contact Icons */}
            <div className="profile-icons">
              <a href={`tel:${user.phone}`} className="icon-button">
                <i className="fas fa-phone"></i>
              </a>
              <a href={`mailto:${user.email}`} className="icon-button">
                <i className="fas fa-envelope"></i>
              </a>
            </div>

            {/* Address and District */}
            <div className="profile-details">
              <p>{user.address || "Address not provided"}</p>
              <p>{user.district || "District not provided"}</p>
            </div>

            {/* Edit Button */}
            <button className="edit-button" onClick={handleEditClick}>
              Edit Profile
            </button>

            {/* Business Section */}
            <div className="business-container">
              <h2>My Business</h2>
              {business ? (
                <div className="business-details">
                  <p><strong>Name:</strong> {business.name}</p>
                  <p><strong>Address:</strong> {business.address || "Not provided"}</p>
                  <p><strong>Description:</strong> {business.description || "Not provided"}</p>
                </div>
              ) : (
                <p>Loading business data...</p>
              )}
            </div>
          </div>
        ) : (
          <p>Loading user data...</p>
        )}
      </div>
    </>
  );
};

export default ProfilePage;





