import Home from './components/Home';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ApiFetchContext from './components/ApiFetchContext';
import MoreMentors from './components/MoreMentors';
import Login from './components/Login';
import RegistrationPage from './components/RegistrationPage';
import ProfilePage from './components/ProfilePage';
import UserDetailsPage from './components/UserDetailsPage';
import MoreBusiness from './components/MoreBusiness';
import EditProfilePage from './components/EditProfilePage';


function App() {
  return (
    <Router>
      <ApiFetchContext>
        <Routes>
          <Route path='/home' element={<Home/>}/>
          <Route path='/more-members' element={<MoreMentors/>}/>
          <Route path='/' element={<Login/>}/>
          <Route path='/register' element={<RegistrationPage/>}/>
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/user/:userId' element={<UserDetailsPage />}/>
          <Route path='/more-business' element={<MoreBusiness />}/>
          <Route path='/edit-profile' element={<EditProfilePage />}/>
      </Routes>
      </ApiFetchContext>
    </Router>
  );
}

export default App;
